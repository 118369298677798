import { call, take } from 'redux-saga/effects';
import { actionTypes, storeSelectedSupplierData } from './reducer';

export function* storeSelectedSupplierDetails(supplierData: any) {
  yield call(storeSelectedSupplierData, supplierData);
}

export function* storeSelectedSupplierDataWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.STORE_SELECTED_SUPPLIER_DATA);

    yield call(storeSelectedSupplierDetails, payload);
  }
}
