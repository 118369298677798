import { call, take } from 'redux-saga/effects';
import { actionTypes, storeSelectedPurchaserData } from './reducer';

export function* storeSelectedPurchaserDetails(purchaserData: any) {
  yield call(storeSelectedPurchaserData, purchaserData);
}

export function* storeSelectedPurchaserDataWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.STORE_SELECTED_SUPPLIER_DATA);

    yield call(storeSelectedPurchaserDetails, payload);
  }
}
