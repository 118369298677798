import React from 'react';
import AppLoader from '../components/AppLoader';
import { RouteComponentProps } from 'react-router';
import { createStructuredSelector } from 'reselect';
import { selectGetCurrentUser, fetchCurrentUser } from 'src/store/users/reducer';
import { connect } from 'react-redux';
import { User } from 'src/store/users/model';
import { actions } from 'src/helpers/Identity';

interface AuthenticateState {
  loading: boolean;
}

interface AuthenticateProps extends RouteComponentProps<any> {
  getCurrentUserDetails: (location: any) => any;
  currentUser: User;
}

export default function (Component: any) {
  class Authenticate extends React.Component<AuthenticateProps, AuthenticateState> {
    public state: AuthenticateState = {
      loading: false
    };

    public async componentDidMount() {
      const redirectUrl = this.props.location.pathname + this.props.location.search;
      await this.setStateAsync({ loading: true });
      try {
        const user = await actions.getUser();
        if (user) {
          if (user.id_token) {
            await this.setStateAsync({ loading: false });
            Object.keys(this.props.currentUser).length === 0
              ? this.props.getCurrentUserDetails(redirectUrl)
              : this.redirectToDefaultPage(redirectUrl);
          } else {
            actions.login();
          }
        } else {
          actions.login();
        }
      } catch (e) {
        actions.login();
      }
    }

    public render() {
      const { loading } = this.state;
      return loading || Object.keys(this.props.currentUser).length === 0 ? (
        <AppLoader />
      ) : (
        <Component {...this.props} />
      );
    }

    private setStateAsync(state: any) {
      return new Promise((resolve) => {
        this.setState(state, resolve);
      });
    }

    private redirectToDefaultPage(redirectUrl: string) {
      const currentUserParty = this.props.currentUser.party || {};
      if (!redirectUrl || redirectUrl === '/' || redirectUrl === '/auth/signin-oidc' || redirectUrl === '/signin') {
        this.props.history.push(
          currentUserParty.role === 1
            ? '/companies?category=0'
            : currentUserParty.role === 2
            ? '/financing-schemes'
            : '/dashboard'
        );
      } else {
        this.props.history.push(redirectUrl);
      }
    }
  }
  function mapStateToProps() {
    return createStructuredSelector({
      currentUser: selectGetCurrentUser()
    });
  }

  function mapDispatchToProps(dispatch: any) {
    return {
      getCurrentUserDetails: (location: any): void => dispatch(fetchCurrentUser(location))
    };
  }
  return connect(mapStateToProps, mapDispatchToProps)(Authenticate);
}
