import devConfig from './app-config';
import prodConfig from './app-prod-config';

function getConfigurations() {
  return window.location.host.match('portal.seaeasycapital.com') ? prodConfig : devConfig;
}

export default {
  authConfig: getConfigurations().authConfig,
  apiBaseUrl: getConfigurations().apiBaseUrl,
  googleRecaptchaKey: getConfigurations().googleRecaptchaKey,
  backgroundInformationServiceApis: getConfigurations().backgroundInformationServiceApis,
  applicationName: getConfigurations().applicationName,
  externalLogins: getConfigurations().externalLogins,
  financingRequestTermsAndConditions: getConfigurations().financingRequestTermsAndConditions,
  sentryDSN: getConfigurations().sentryDSN,
  hotJarConfig: getConfigurations().hotJarConfig
};
