import { fromJS } from 'immutable';
import { Purchaser, PurchaserAction } from './model';
import { createSelector } from 'reselect';

// State
const initialState = fromJS({
  selectedPurchaser: {}
});

// Action Types
export const actionTypes = {
  STORE_SELECTED_SUPPLIER_DATA: 'SUPPLIERS/STORE_SELECTED_SUPPLIER_DATA'
};

// Selectors
const purchaserDomain = () => (state: any) => state.get('purchaserDomain');

const selectPurchaserDataStored = () =>
  createSelector(purchaserDomain(), (purchaserState) => purchaserState.get('selectedPurchaser').toJS());

export { selectPurchaserDataStored };

// Actions
export const storeSelectedPurchaserData = (data: Purchaser) => {
  return {
    type: actionTypes.STORE_SELECTED_SUPPLIER_DATA,
    payload: data
  };
};

// Reducers
export default (state = initialState, action: PurchaserAction) => {
  switch (action.type) {
    case actionTypes.STORE_SELECTED_SUPPLIER_DATA:
      return state.set('selectedPurchaser', fromJS(action.payload));
    default:
      return state;
  }
};
