import { call, take } from 'redux-saga/effects';
import { storeApiResultData, actionTypes } from './reducer';

export function* storeApiResultDetails(userData: any) {
  yield call(storeApiResultData, userData);
}

export function* storeApiResultDataWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.STORE_API_REQUEST_RESULT_DATA);

    yield call(storeApiResultDetails, payload);
  }
}
