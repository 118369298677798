import { call, take } from 'redux-saga/effects';
import { actionTypes, storeSelectedReceivableData, storeSelectedReceivableDraftData } from './reducer';

export function* storeSelectedReceivableDetails(receivableData: any) {
  yield call(storeSelectedReceivableData, receivableData);
}

export function* storeSelectedReceivableDataWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.STORE_SELECTED_RECEIVABLE_DATA);

    yield call(storeSelectedReceivableDetails, payload);
  }
}

export function* storeSelectedReceivableDraftDetails(receivableData: any) {
  yield call(storeSelectedReceivableDraftData, receivableData);
}

export function* storeSelectedReceivableDraftDataWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.STORE_SELECTED_RECEIVABLE_DRAFT_DATA);

    yield call(storeSelectedReceivableDraftDetails, payload);
  }
}
