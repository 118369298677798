import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { state } from './root-reducer';
import sagas from './root-saga';
import initialState from './root-state';
import { routerMiddleware } from 'connected-react-router/immutable';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sagaMiddleware = createSagaMiddleware();
const routersMiddleware = routerMiddleware(history);

const enhancer = compose(applyMiddleware(sagaMiddleware, routersMiddleware));

const store: any = createStore(
  state(history), // root reducer with router state
  initialState!,
  enhancer
);

sagaMiddleware.run(sagas);

export default store;
