const APPLICATION_BASE_URL = window.location.origin;
const BIS_BASE_URL = 'https://utilityservices-dev.seaeasycapital.com';
// const IDENTITYSERVER_BASE_URL = 'https://localhost:44301';
// const API_BASE_URL = 'https://localhost:5001';
const IDENTITYSERVER_BASE_URL = 'https://auth-dev.seaeasycapital.com';
const API_BASE_URL = 'https://api-dev.seaeasycapital.com';

const configurations = {
  authConfig: {
    authority: IDENTITYSERVER_BASE_URL,
    // client_id: 'devLocal',
    client_id: 'spa',
    redirect_uri: `${APPLICATION_BASE_URL}/auth/signin-oidc`,
    response_type: 'id_token token',
    scope: 'openid profile',
    post_logout_redirect_uri: `${APPLICATION_BASE_URL}/signin`
  },
  apiBaseUrl: API_BASE_URL,
  googleRecaptchaKey: '6Les140aAAAAAPMormxklCmqXXPG-TpTJm3VEImy',
  backgroundInformationServiceApis: {
    industryClassificationUrl: `${BIS_BASE_URL}/api/Classification`,
    getIndustryclassificationCode: (standard: any, code: any) =>
      `${BIS_BASE_URL}/api/Classification/lookup/${standard}/${code}`,
    getPartyLegalForms: `${BIS_BASE_URL}/api/BasicEntityCheck/legal-forms`
  },
  xeroReceivablesExternalUrl: 'https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=',
  xeroPayablesExternalUrl: 'https://go.xero.com/AccountsPayable/View.aspx?InvoiceID=',
  applicationName: 'Sea Easy Capital',
  externalLogins: {
    identityServerBaseUrl: IDENTITYSERVER_BASE_URL,
    singPass: `${IDENTITYSERVER_BASE_URL}/External/Challenge?scheme=SingPass&source=register`
  },
  financingRequestTermsAndConditions: {
    formOfNoticeofAssignment:
      'https://dev-documentdb.s3.ap-southeast-1.amazonaws.com/Public/Form+of+Notice+of+Assignment+.pdf',
    formOfAcknowledgmentOfNoticeOfAssignment:
      'https://dev-documentdb.s3.ap-southeast-1.amazonaws.com/Public/Form+of+Acknowledgment+of+Notice+of+Assignment.pdf'
  },
  sentryDSN: 'https://3205bda75cb24228b14cb8921983e0c2@sentry.itelalabs.net/12',
  hotJarConfig: {
    hjid: 2672767,
    hjsv: 6
  }
};

export default configurations;
