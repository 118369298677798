import { fromJS } from 'immutable';
import { Receivable, ReceivableAction } from './model';
import { createSelector } from 'reselect';

// State
const initialState = fromJS({
  selectedReceivable: {},
  selectedReceivableDraft: {}
});

// Action Types
export const actionTypes = {
  STORE_SELECTED_RECEIVABLE_DATA: 'RECEIVABLES/STORE_SELECTED_RECEIVABLE_DATA',
  STORE_SELECTED_RECEIVABLE_DRAFT_DATA: 'RECEIVABLES/STORE_SELECTED_RECEIVABLE_DRAFT_DATA'
};

// Selectors
const receivableDomain = () => (state: any) => state.get('receivableDomain');

const selectReceivableDataStored = () =>
  createSelector(receivableDomain(), (receivableState) => receivableState.get('selectedReceivable').toJS());

const selectReceivableDraftDataStored = () =>
  createSelector(receivableDomain(), (receivableState) => receivableState.get('selectedReceivableDraft').toJS());

export { selectReceivableDataStored, selectReceivableDraftDataStored };

// Actions
export const storeSelectedReceivableData = (data: Receivable) => {
  return {
    type: actionTypes.STORE_SELECTED_RECEIVABLE_DATA,
    payload: data
  };
};

export const storeSelectedReceivableDraftData = (data: any) => {
  return {
    type: actionTypes.STORE_SELECTED_RECEIVABLE_DRAFT_DATA,
    payload: data
  };
};

// Reducers
export default (state = initialState, action: ReceivableAction) => {
  switch (action.type) {
    case actionTypes.STORE_SELECTED_RECEIVABLE_DATA:
      return state.set('selectedReceivable', fromJS(action.payload));
    case actionTypes.STORE_SELECTED_RECEIVABLE_DRAFT_DATA:
      return state.set('selectedReceivableDraft', fromJS(action.payload));
    default:
      return state;
  }
};
