import { fromJS } from 'immutable';
import { Payable, PayableAction } from './model';
import { createSelector } from 'reselect';

// State
const initialState = fromJS({
  selectedPayable: {},
  selectedPayableDraft: {}
});

// Action Types
export const actionTypes = {
  STORE_SELECTED_PAYABLE_DATA: 'PAYABLES/STORE_SELECTED_PAYABLE_DATA',
  STORE_SELECTED_PAYABLE_DRAFT_DATA: 'PAYABLES/STORE_SELECTED_PAYABLE_DRAFT_DATA'
};

// Selectors
const payableDomain = () => (state: any) => state.get('payableDomain');

const selectPayableDataStored = () =>
  createSelector(payableDomain(), (payableState) => payableState.get('selectedPayable').toJS());

const selectPayableDraftDataStored = () =>
  createSelector(payableDomain(), (payableState) => payableState.get('selectedPayableDraft').toJS());

export { selectPayableDataStored, selectPayableDraftDataStored };

// Actions
export const storeSelectedPayableData = (data: Payable) => {
  return {
    type: actionTypes.STORE_SELECTED_PAYABLE_DATA,
    payload: data
  };
};

export const storeSelectedPayableDraftData = (data: any) => {
  return {
    type: actionTypes.STORE_SELECTED_PAYABLE_DRAFT_DATA,
    payload: data
  };
};

// Reducers
export default (state = initialState, action: PayableAction) => {
  switch (action.type) {
    case actionTypes.STORE_SELECTED_PAYABLE_DATA:
      return state.set('selectedPayable', fromJS(action.payload));
    case actionTypes.STORE_SELECTED_PAYABLE_DRAFT_DATA:
      return state.set('selectedPayableDraft', fromJS(action.payload));
    default:
      return state;
  }
};
