import { call, take } from 'redux-saga/effects';
import { actionTypes, storeSelectedPayableData, storeSelectedPayableDraftData } from './reducer';

export function* storeSelectedPayableDetails(payableData: any) {
  yield call(storeSelectedPayableData, payableData);
}

export function* storeSelectedPayableDataWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.STORE_SELECTED_PAYABLE_DATA);

    yield call(storeSelectedPayableDetails, payload);
  }
}

export function* storeSelectedPayableDraftDetails(payableData: any) {
  yield call(storeSelectedPayableDraftData, payableData);
}

export function* storeSelectedPayableDraftDataWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.STORE_SELECTED_PAYABLE_DRAFT_DATA);

    yield call(storeSelectedPayableDraftDetails, payload);
  }
}
