import { call, put, take } from 'redux-saga/effects';
import { Country } from './model';
import { fetchCountryList } from './service';
import { fetchCountryListSuccess, fetchCountryListError, actionTypes } from './reducer';

export function* fetchCountries() {
  try {
    const result: Country[] = yield call(fetchCountryList);
    yield put(fetchCountryListSuccess(result));
  } catch (err) {
    yield put(fetchCountryListError(err));
  }
}

export function* fetchCountriestWatcher() {
  while (true) {
    yield take(actionTypes.COUNTRY_LIST_FETCH);

    yield call(fetchCountries);
  }
}
