import { call, put, take, delay } from 'redux-saga/effects';
import {
  actionTypes,
  fetchCurrentUserSuccess,
  fetchCurrentUserError,
  storeUserRegistrationData,
  fetchUpdateCurrentUserSuccess,
  fetchUpdateCurrentUserError
} from './reducer';
import { User } from './model';
import { fetchCurrentUserDetails, fetchUpdateUser } from './service';
import { storeApiResultData } from '../common/reducer';
import { fetchCurrentUserPartyLogoUrl, fetchDefaultPartyLogoUrl } from '../parties/party/reducer';
import { push } from 'connected-react-router';

export function* updateError(data: any) {
  yield put(storeApiResultData(data));
}

export function* fetchCurrentUserData(redirectUrl: any) {
  try {
    const result: User = yield call(fetchCurrentUserDetails);

    yield put(fetchCurrentUserSuccess(result));
    const userParty = result.party || {};
    const language = userParty.language ? userParty.language.localeCode : 'en';
    if (userParty.logoReference) {
      yield put(fetchCurrentUserPartyLogoUrl(userParty.logoReference));
    } else {
      yield put(fetchDefaultPartyLogoUrl());
    }
    if (!redirectUrl || redirectUrl === '/' || redirectUrl === '/auth/signin-oidc' || redirectUrl === '/signin') {
      yield put(
        push(
          userParty.role === 1 ? '/companies?category=0' : userParty.role === 2 ? '/financing-schemes' : '/dashboard'
        )
      );
    } else {
      yield put(push(redirectUrl));
    }

    localStorage.setItem('language', language || 'en');
  } catch (err) {
    yield put(fetchCurrentUserError(err));
  }
}

export function* fetchCurrentUserWatcher() {
  while (true) {
    const { url } = yield take(actionTypes.CURRENT_USER_FETCH);

    yield call(fetchCurrentUserData, url);
  }
}

export function* storeUserRegistrationDetails(userData: any) {
  yield call(storeUserRegistrationData, userData);
}

export function* storeUserRegistrationDataWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.STORE_USER_REGISTRATION_DATA);

    yield call(storeUserRegistrationDetails, payload);
  }
}

export function* fetchUpdateCurrentUserData(userData: any) {
  try {
    const result: User = yield call(fetchUpdateUser, userData);
    yield put(fetchUpdateCurrentUserSuccess(result));
    window.scrollTo(0, 0);

    yield call(updateError, {
      showMessageClass: 'alert-success',
      messageCode: 'SuccessMessage.ProfileSuccessfullyUpdated',
      showMessageKey: new Date().toUTCString(),
      visibleClass: 'show-alert'
    });

    yield delay(3000);
    yield call(updateError, {
      visibleClass: ''
    });
  } catch (err) {
    yield put(fetchUpdateCurrentUserError(err));
    window.scrollTo(0, 0);

    yield call(updateError, {
      showMessageClass: 'alert-danger',
      messageCode: 'ErrorMessage.ProfileUpdateFailed',
      showMessageKey: new Date().toUTCString(),
      visibleClass: 'show-alert'
    });

    yield delay(3000);
    yield call(updateError, {
      visibleClass: ''
    });
  }
}

export function* fetchUpdateCurrentUserWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.UPDATE_CURRENT_USER_FETCH);

    yield call(fetchUpdateCurrentUserData, payload);
  }
}
