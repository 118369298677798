import * as React from 'react';
import Loadable from 'react-loadable';
import { Redirect, Route, Switch } from 'react-router-dom';
import AppLoader from './components/AppLoader';
import Home from './Home';
import requireAuth from './helpers/requireAuth';
import Callback from './auth/Callback';
import RouteNotFound from './RouteNotFound';

// Code splitting with dynamic import
// https://reactjs.org/docs/code-splitting.html
const Payables = Loadable({
  loader: () => import('./payables'),
  loading: AppLoader
});

const Receivables = Loadable({
  loader: () => import('./receivables'),
  loading: AppLoader
});

const Suppliers = Loadable({
  loader: () => import('./suppliers'),
  loading: AppLoader
});

const Auth = Loadable({
  loader: () => import('./auth'),
  loading: AppLoader
});

const Profile = Loadable({
  loader: () => import('./profile'),
  loading: AppLoader
});

const Party = Loadable({
  loader: () => import('./party'),
  loading: AppLoader
});

const InviteParty = Loadable({
  loader: () => import('./inviteParty'),
  loading: AppLoader
});

const Parties = Loadable({
  loader: () => import('./parties'),
  loading: AppLoader
});

const Financing = Loadable({
  loader: () => import('./financing'),
  loading: AppLoader
});

const Purchasers = Loadable({
  loader: () => import('./purchasers'),
  loading: AppLoader
});

const EarlyPayments = Loadable({
  loader: () => import('./earlyPayments'),
  loading: AppLoader
});

const PurchaserPortfolios = Loadable({
  loader: () => import('./purchaserPortfolios'),
  loading: AppLoader
});

const Integrations = Loadable({
  loader: () => import('./integrations'),
  loading: AppLoader
});

const PaymentCycles = Loadable({
  loader: () => import('./paymentCycles'),
  loading: AppLoader
});

const TransferAdvices = Loadable({
  loader: () => import('./transferAdvices'),
  loading: AppLoader
});

const ImportHistory = Loadable({
  loader: () => import('./importHistory'),
  loading: AppLoader
});

const Repayments = Loadable({
  loader: () => import('./repayments'),
  loading: AppLoader
});

const Debtors = Loadable({
  loader: () => import('./debtors'),
  loading: AppLoader
});

const ReconcilationCycles = Loadable({
  loader: () => import('./reconcilationCycles'),
  loading: AppLoader
});

const SecuredPartyGroups = Loadable({
  loader: () => import('./securedPartyGroups'),
  loading: AppLoader
});

const NewDashboard = Loadable({
  loader: () => import('./newDashboard'),
  loading: AppLoader
});

const Videos = Loadable({
  loader: () => import('./helpAndSupport'),
  loading: AppLoader
});

export const routes = (
  <div>
    <Switch>
      <Route exact={true} path="/" render={() => <Redirect to="/signin" />} />
      <Route path="/signin" component={requireAuth(Home)} />
      <Route path="/register" component={Auth} />
      <Route path="/receivables" component={requireAuth(Receivables)} />
      <Route path="/payables" component={requireAuth(Payables)} />
      <Route path="/suppliers" component={requireAuth(Suppliers)} />
      <Route path="/profile" component={requireAuth(Profile)} />
      <Route path="/my-company" component={requireAuth(Party)} />
      <Route path="/invite-party" component={requireAuth(InviteParty)} />
      <Route path="/companies" component={requireAuth(Parties)} />
      <Route path="/financing" component={requireAuth(Financing)} />
      <Route path="/purchasers" component={requireAuth(Purchasers)} />
      <Route path="/early-payments" component={requireAuth(EarlyPayments)} />
      <Route path="/financing-schemes" component={requireAuth(PurchaserPortfolios)} />
      <Route path="/integrations" component={requireAuth(Integrations)} />
      <Route path="/payment-cycles" component={requireAuth(PaymentCycles)} />
      <Route path="/cash-flow" component={requireAuth(TransferAdvices)} />
      <Route path="/import-history" component={requireAuth(ImportHistory)} />
      <Route path="/repayments" component={requireAuth(Repayments)} />
      <Route path="/debtors" component={requireAuth(Debtors)} />
      <Route path="/reconcilation-cycles" component={requireAuth(ReconcilationCycles)} />
      <Route path="/secured-party-groups" component={requireAuth(SecuredPartyGroups)} />
      <Route path="/dashboard" component={requireAuth(NewDashboard)} />
      <Route path="/videos" component={Videos} />
      <Route path="/auth/signin-oidc" component={Callback} />
      <Route path="*" component={RouteNotFound} />
    </Switch>
  </div>
);
