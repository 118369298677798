import { get, post, put } from '../../helpers/apiHelper';
import { remove } from '../../helpers/apiHelper';
import { actions } from '../../helpers/Identity';

// Save New User Details
export const fetchAddUser = async (requestData: any) => {
  const requestFormData = new FormData();

  for (const key in requestData) {
    if (requestData.hasOwnProperty(key)) {
      requestFormData.append(key, requestData[key]);
    }
  }

  try {
    const result = await post(`/api/account/user`, requestFormData, false, 'multipart/form-data');

    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Update Current User Password
export const fetchUpdateUserPassword = async (requestData: any) => {
  try {
    const result = await post(`/api/account/changepassword`, requestData, true);

    if (result.status === 200 || result.status === 201) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

// Get Current Logged In User Details
export const fetchCurrentUserDetails = async () => {
  const result = await get('/api/account/currentuser', true);
  try {
    if (result.data.status) {
      if (
        result.data.value.identityDocumentType === 0 &&
        !result.data.value.identityDocumentTypeId &&
        result.data.value.roleCode !== 1
      ) {
        window.location.href = `/register/addUser?userId=${result.data.value.id}`;
        return {};
      } else {
        return result.data.value;
      }
    } else {
      throw result.data;
    }
  } catch (e) {
    throw e;
  }
};

// Add User To Party
export const fetchAddUserToParty = async (requestData: any) => {
  try {
    const result = await post(`/api/account/addUserToParty`, requestData, true);

    if (result.data.status) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

// Update Existing User Details
export const fetchUpdateUser = async (requestData: any) => {
  try {
    const result = await put(`/api/account/user`, requestData, true);
    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Verify User Phone Number
export const fetchVerifyPhoneNumberCode = async (requestData: any) => {
  try {
    const result = await post(
      `/api/Account/verifyVerificationCode?userId=${requestData.userId}&phoneNumber=${requestData.phoneNumber}&verificationCode=${requestData.verificationCode}`,
      null
    );

    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Send Verification Code To User Phone Number
export const sendVerificationCode = async (requestData: any) => {
  try {
    const result = await post(
      `/api/Account/sendVerificationSMS?userId=${requestData.userId}&phoneNumber=${requestData.phoneNumber}`,
      null
    );

    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Add or Update User in Party
export const fetchUpdatePartyUser = async (requestData: any) => {
  const requestType = requestData.id ? put : post;
  const requestUrl = requestData.id ? `/api/account/user` : `/api/Account/addUserToParty`;
  try {
    const result = await requestType(requestUrl, requestData, true);
    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Get user Details By User ID
export const fetchUser = async (id: string) => {
  try {
    const result = await get(`/api/Account/user/${id}`, false);
    if (result.status === 200 || result.status === 201) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

// Delete User By User ID
export const fetchDeleteUser = async (id: string) => {
  try {
    const result = await remove(`/api/Account/user/${id}`, true);
    if (result.data.status) {
      return result;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

// Save User Teams
export const fetchUpdateUserTeams = async (userId: any, teamsData: any) => {
  try {
    const result = await post(`/api/TeamUser/${userId}/Teams`, teamsData, true);

    if (result.data.status) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

// Get Teams Of A User
export const fetchUserTeams = async (userId: string) => {
  try {
    const result = await get(`/api/TeamUser/${userId}/Teams`, true);
    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Get User Roles Of a User
export const fetchUserRoles = async (userId: string) => {
  try {
    const result = await get(`/api/Account/user/${userId}/roles`, true);
    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Get All User Roles
export const fetchUserRoleList = async (target: number) => {
  try {
    const result = await get(`/api/Account/allRoles?targetUsers=${target}`, true);
    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

export const logoutUser = async () => {
  actions.logout();
};

// Save User Roles
export const fetchUpdateUserRoles = async (userId: any, rolesData: any) => {
  try {
    const result = await post(`/api/Account/user/${userId}/role`, rolesData, true);

    if (result.data.status) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

// Delete User Roles
export const fetchDeleteUserRole = async (userId: any, roleId: any) => {
  try {
    const result = await remove(`/api/Account/user/${userId}/role/${roleId}`, true);
    if (result.data.status) {
      return result;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

// Get user Details By User Email
export const fetchUserByEmail = async (email: string) => {
  try {
    const result = await get(`/api/Account/user-email?email=${encodeURIComponent(email)}`, true);
    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUserExternalIdentities = async () => {
  try {
    const result = await get(`/api/account/identities`, true, '');
    if (result.status === 200 || result.status === 201) {
      return result.data;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDeleteUserExternalIdentities = async (provider: string) => {
  try {
    const result = await remove(`/api/account/identities/externalproviders/${provider}`, true);
    if (result.status === 200 || result.status === 201) {
      return true;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};
