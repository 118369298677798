import { combineReducers } from 'redux-immutable';

import usersReducer from './users/reducer';
import partiesReducer from './parties/party/reducer';
import countriesReducer from './countries/reducer';
import legalEntityReducer from './parties/legalEntities/legalEntity/reducer';
import commonReducer from './common/reducer';
import supplierReducer from './suppliers/reducer';
import payableReducer from './payables/reducer';
import purchaserReducer from './purchasers/reducer';
import receivableReducer from './receivables/reducer';
import financingSchemeReducer from './purchaserPortfolios/financingSchemes/reducer';
import { connectRouter, RouterState } from 'connected-react-router/immutable';

export interface State {
  usersDomain: any;
  partiesDomain: any;
  countriesDomain: any;
  legalEntityDomain: any;
  commonDomain: any;
  supplierDomain: any;
  payableDomain: any;
  purchaserDomain: any;
  receivableDomain: any;
  financingSchemeDomain: any;
  router: RouterState;
}

export const state = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    usersDomain: usersReducer,
    partiesDomain: partiesReducer,
    countriesDomain: countriesReducer,
    legalEntityDomain: legalEntityReducer,
    commonDomain: commonReducer,
    supplierDomain: supplierReducer,
    payableDomain: payableReducer,
    purchaserDomain: purchaserReducer,
    receivableDomain: receivableReducer,
    financingSchemeDomain: financingSchemeReducer
  });
