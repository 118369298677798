import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { routes } from './Routes';
import './styles/style.css';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'uppy/dist/uppy.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import momentTimeZone from 'moment-timezone';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

class App extends React.Component {
  constructor(props: any) {
    super(props);
    moment.locale('en');
    momentTimeZone.tz.setDefault('UTC');
  }

  public render() {
    return <div className="App">{routes}</div>;
  }
}

export default App;
