import { fromJS } from 'immutable';
import { PartyLegalEntity, PartyLegalEntityAction } from './model';
import { createSelector } from 'reselect';

// State
const initialState = fromJS({
  selectedLegalEntity: {}
});

// Action Types
export const actionTypes = {
  STORE_SELECTED_LEGAL_ENTITY_DATA: 'LEGAL_ENTITIES/STORE_SELECTED_LEGAL_ENTITY_DATA'
};

// Selectors
const legalEntityDomain = () => (state: any) => state.get('legalEntityDomain');

const selectLegalEntityDataStored = () =>
  createSelector(legalEntityDomain(), (legalEntityState) => legalEntityState.get('selectedLegalEntity').toJS());

export { selectLegalEntityDataStored };

// Actions
export const storeSelectedLegalEntityData = (data: PartyLegalEntity) => {
  return {
    type: actionTypes.STORE_SELECTED_LEGAL_ENTITY_DATA,
    payload: data
  };
};

// Reducers
export default (state = initialState, action: PartyLegalEntityAction) => {
  switch (action.type) {
    case actionTypes.STORE_SELECTED_LEGAL_ENTITY_DATA:
      return state.set('selectedLegalEntity', fromJS(action.payload));
    default:
      return state;
  }
};
