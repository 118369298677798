import { fromJS } from 'immutable';
import { createSelector } from 'reselect';

// State
const initialState = fromJS({
  apiRequestResultData: {}
});

// Action Types
export const actionTypes = {
  STORE_API_REQUEST_RESULT_DATA: 'COMMON/STORE_API_REQUEST_RESULT_DATA'
};

// Selectors
const getCommonDomain = () => (state: any) => state.get('commonDomain');

const selectStoreApiRequestResultData = () =>
  createSelector(getCommonDomain(), (commonState) => commonState.get('apiRequestResultData').toJS());

export { selectStoreApiRequestResultData };

// Actions
export const storeApiResultData = (data: any) => {
  return {
    type: actionTypes.STORE_API_REQUEST_RESULT_DATA,
    payload: data
  };
};

// Reducers
export default (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.STORE_API_REQUEST_RESULT_DATA:
      return state.set('apiRequestResultData', fromJS(action.payload));
    default:
      return state;
  }
};
