import { UserManager, WebStorageStateStore } from 'oidc-client';
import configurations from '../config';

const settings = { ...configurations.authConfig, userStore: new WebStorageStateStore({ store: window.localStorage }) };
const userManager = new UserManager(settings);

export const actions = {
  async login() {
    return userManager.signinRedirect();
  },

  async signinRedirectCallback() {
    return await userManager.signinRedirectCallback();
  },

  async getUser() {
    return await userManager.getUser();
  },

  async getAccessToken() {
    const user: any = await userManager.getUser();
    return user ? user.access_token : '';
  },

  async logout() {
    await userManager.signoutRedirect();
    await userManager.clearStaleState();
    localStorage.clear();
  }
};
