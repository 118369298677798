import { get } from '../../helpers/apiHelper';

// Get Country List
export const fetchCountryList = async () => {
  try {
    const result = await get(`/api/values/country`, true);

    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};
