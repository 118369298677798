import { fromJS } from 'immutable';
import { Supplier, SupplierAction } from './model';
import { createSelector } from 'reselect';

// State
const initialState = fromJS({
  selectedSupplier: {}
});

// Action Types
export const actionTypes = {
  STORE_SELECTED_SUPPLIER_DATA: 'SUPPLIERS/STORE_SELECTED_SUPPLIER_DATA'
};

// Selectors
const supplierDomain = () => (state: any) => state.get('supplierDomain');

const selectSupplierDataStored = () =>
  createSelector(supplierDomain(), (supplierState) => supplierState.get('selectedSupplier').toJS());

export { selectSupplierDataStored };

// Actions
export const storeSelectedSupplierData = (data: Supplier) => {
  return {
    type: actionTypes.STORE_SELECTED_SUPPLIER_DATA,
    payload: data
  };
};

// Reducers
export default (state = initialState, action: SupplierAction) => {
  switch (action.type) {
    case actionTypes.STORE_SELECTED_SUPPLIER_DATA:
      return state.set('selectedSupplier', fromJS(action.payload));
    default:
      return state;
  }
};
