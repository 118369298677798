import { fromJS } from 'immutable';
import { User } from './model';
import { UserAction } from './model';
import { createSelector } from 'reselect';

// State
const initialState = fromJS({
  users: [],
  isUpdateUserLoading: false,
  isUpdateUserFetched: false,
  currentUser: {},
  userRegistrationData: {}
});

// Action Types
export const actionTypes = {
  CURRENT_USER_FETCH: 'USERS/CURRENT_USER_FETCH',
  CURRENT_USER_FETCH_SUCCESS: 'USERS/CURRENT_USER_FETCH_SUCCESS',
  CURRENT_USER_FETCH_ERROR: 'USERS/CURRENT_USER_FETCH_ERROR',
  STORE_USER_REGISTRATION_DATA: 'USERS/STORE_USER_REGISTRATION_DATA',
  UPDATE_CURRENT_USER_FETCH: 'USERS/UPDATE_CURRENT_USER_FETCH',
  UPDATE_CURRENT_USER_FETCH_SUCCESS: 'USERS/UPDATE_CURRENT_USER_FETCH_SUCCESS',
  UPDATE_CURRENT_USER_FETCH_ERROR: 'USERS/UPDATE_CURRENT_USER_FETCH_ERROR'
};

// Selectors
const getUsersDomain = () => (state: any) => state.get('usersDomain');

const selectGetCurrentUser = () =>
  createSelector(getUsersDomain(), (usersState) => usersState.get('currentUser').toJS());

const selectIsGetCurrentUserLoading = () =>
  createSelector(getUsersDomain(), (usersState) => usersState.get('isGetCurrentUserLoading'));

const selectIsGetCurrentUserFetched = () =>
  createSelector(getUsersDomain(), (usersState) => usersState.get('isGetCurrentUserFetched'));

const selectIsGetCurrentUserError = () =>
  createSelector(getUsersDomain(), (usersState) => usersState.get('getCurrentUserError'));

const selectUserRegistrationDataStored = () =>
  createSelector(getUsersDomain(), (usersState) => usersState.get('userRegistrationData').toJS());

const selectIsUpdateCurrentUserLoading = () =>
  createSelector(getUsersDomain(), (usersState) => usersState.get('isUpdateCurrentUserLoading'));

const selectIsUpdateCurrentUserFetched = () =>
  createSelector(getUsersDomain(), (usersState) => usersState.get('isUpdateCurrentUserFetched'));

const selectIsUpdateCurrentUserError = () =>
  createSelector(getUsersDomain(), (usersState) => usersState.get('updateCurrentUserError'));

export {
  selectGetCurrentUser,
  selectIsGetCurrentUserLoading,
  selectIsGetCurrentUserFetched,
  selectUserRegistrationDataStored,
  selectIsGetCurrentUserError,
  selectIsUpdateCurrentUserLoading,
  selectIsUpdateCurrentUserFetched,
  selectIsUpdateCurrentUserError
};

// Actions
export const fetchCurrentUser = (redirectUrl: string) => {
  return {
    type: actionTypes.CURRENT_USER_FETCH,
    url: redirectUrl
  };
};

export const fetchCurrentUserSuccess = (result: User) => {
  return {
    type: actionTypes.CURRENT_USER_FETCH_SUCCESS,
    payload: result
  };
};

export const fetchCurrentUserError = (error: Error) => {
  return {
    type: actionTypes.CURRENT_USER_FETCH_ERROR,
    payload: error
  };
};

export const storeUserRegistrationData = (data: User) => {
  return {
    type: actionTypes.STORE_USER_REGISTRATION_DATA,
    payload: data
  };
};

export const fetchUpdateCurrentUser = (data: User) => {
  return {
    type: actionTypes.UPDATE_CURRENT_USER_FETCH,
    payload: data
  };
};

export const fetchUpdateCurrentUserSuccess = (result: User) => {
  return {
    type: actionTypes.UPDATE_CURRENT_USER_FETCH_SUCCESS,
    payload: result
  };
};

export const fetchUpdateCurrentUserError = (error: Error) => {
  return {
    type: actionTypes.UPDATE_CURRENT_USER_FETCH_ERROR,
    payload: error
  };
};

// Reducers
export default (state = initialState, action: UserAction) => {
  switch (action.type) {
    case actionTypes.CURRENT_USER_FETCH:
      return state
        .set('isGetCurrentUserLoading', true)
        .set('isGetCurrentUserFetched', false)
        .set('currentUser', initialState.get('currentUser'));
    case actionTypes.CURRENT_USER_FETCH_SUCCESS:
      return state
        .set('isGetCurrentUserLoading', false)
        .set('isGetCurrentUserFetched', true)
        .set('currentUser', fromJS(action.payload));
    case actionTypes.CURRENT_USER_FETCH_ERROR:
      return state
        .set('isGetCurrentUserLoading', false)
        .set('isGetCurrentUserFetched', false)
        .set('getCurrentUserError', fromJS(action.payload));

    case actionTypes.STORE_USER_REGISTRATION_DATA:
      return state.set('userRegistrationData', fromJS(action.payload));
    case actionTypes.UPDATE_CURRENT_USER_FETCH:
      return state.set('isUpdateCurrentUserLoading', true).set('isUpdateCurrentUserFetched', false);
    case actionTypes.UPDATE_CURRENT_USER_FETCH_SUCCESS:
      return state
        .set('isUpdateCurrentUserLoading', false)
        .set('isUpdateCurrentUserFetched', true)
        .set('currentUser', fromJS(action.payload));
    case actionTypes.UPDATE_CURRENT_USER_FETCH_ERROR:
      return state
        .set('isUpdateCurrentUserLoading', false)
        .set('isUpdateCurrentUserFetched', false)
        .set('updateCurrentUserError', fromJS(action.payload));
    default:
      return state;
  }
};
