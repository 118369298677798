import { fromJS } from 'immutable';
import { FinancingScheme, FinancingSchemeAction } from './model';
import { createSelector } from 'reselect';

// State
const initialState = fromJS({
  selectedFinancingScheme: {}
});

// Action Types
export const actionTypes = {
  STORE_SELECTED_FINANCING_SCHEME_DATA: 'FINANCING_SCHEMES/STORE_SELECTED_FINANCING_SCHEME_DATA'
};

// Selectors
const financingSchemeDomain = () => (state: any) => state.get('financingSchemeDomain');

const selectFinancingSchemeDataStored = () =>
  createSelector(financingSchemeDomain(), (financingSchemeState) =>
    financingSchemeState.get('selectedFinancingScheme').toJS()
  );

export { selectFinancingSchemeDataStored };

// Actions
export const storeSelectedFinancingSchemeData = (data: FinancingScheme) => {
  return {
    type: actionTypes.STORE_SELECTED_FINANCING_SCHEME_DATA,
    payload: data
  };
};

// Reducers
export default (state = initialState, action: FinancingSchemeAction) => {
  switch (action.type) {
    case actionTypes.STORE_SELECTED_FINANCING_SCHEME_DATA:
      return state.set('selectedFinancingScheme', fromJS(action.payload));
    default:
      return state;
  }
};
