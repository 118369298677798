import { fromJS } from 'immutable';
import { PartyDetails, Party } from './model';
import { PartyAction } from './model';
import { createSelector } from 'reselect';

// State
const initialState = fromJS({
  parties: [],
  isGetPartyByIdentificationLoading: false,
  isGetPartyByIdentificationFetched: false,
  partyByIdentificationError: '',
  partyDetails: {},
  party: {},
  partyList: [],
  currentUserPartyLogoUrl: '',
  defaultPartyLogoUrl: ''
});

// Action Types
export const actionTypes = {
  PARTY_BY_IDENTIFICATION_FETCH: 'PARTIES/PARTY_BY_IDENTIFICATION_FETCH',
  PARTY_BY_IDENTIFICATION_FETCH_SUCCESS: 'PARTIES/PARTY_BY_IDENTIFICATION_FETCH_SUCCESS',
  PARTY_BY_IDENTIFICATION_FETCH_ERROR: 'PARTIES/PARTY_BY_IDENTIFICATION_FETCH_ERROR',
  PARTY_BY_ID_FETCH: 'PARTIES/PARTY_BY_ID_FETCH',
  PARTY_BY_ID_FETCH_SUCCESS: 'PARTIES/PARTY_BY_ID_FETCH_SUCCESS',
  PARTY_BY_ID_FETCH_ERROR: 'PARTIES/PARTY_BY_ID_FETCH_ERROR',
  UPDATE_PARTY_FETCH: 'PARTIES/UPDATE_PARTY_FETCH',
  UPDATE_PARTY_FETCH_SUCCESS: 'PARTIES/UPDATE_PARTY_FETCH_SUCCESS',
  UPDATE_PARTY_FETCH_ERROR: 'PARTIES/UPDATE_PARTY_FETCH_ERROR',
  PARTY_NAME_LIST_FETCH: 'PARTIES/PARTY_NAME_LIST_FETCH',
  PARTY_NAME_LIST_FETCH_SUCCESS: 'PARTIES/PARTY_NAME_LIST_FETCH_SUCCESS',
  PARTY_NAME_LIST_FETCH_ERROR: 'PARTIES/PARTY_NAME_LIST_FETCH_ERROR',
  CURRENT_USER_PARTY_LOGO_URL_FETCH: 'PARTIES/CURRENT_USER_PARTY_LOGO_URL_FETCH',
  CURRENT_USER_PARTY_LOGO_URL_FETCH_SUCCESS: 'PARTIES/CURRENT_USER_PARTY_LOGO_URL_FETCH_SUCCESS',
  CURRENT_USER_PARTY_LOGO_URL_FETCH_ERROR: 'PARTIES/CURRENT_USER_PARTY_LOGO_URL_FETCH_ERROR',
  DEFAULT_LOGO_URL_FETCH: 'PARTIES/DEFAULT_LOGO_URL_FETCH',
  DEFAULT_LOGO_URL_FETCH_SUCCESS: 'PARTIES/DEFAULT_LOGO_URL_FETCH_SUCCESS',
  DEFAULT_LOGO_URL_FETCH_ERROR: 'PARTIES/DEFAULT_LOGO_URL_FETCH_ERROR',
  UPDATE_PARTY_ONBOARD_STATUS_FETCH: 'PARTIES/UPDATE_PARTY_ONBOARD_STATUS_FETCH'
};

// Selectors
const getPartiesDomain = () => (state: any) => state.get('partiesDomain');

const selectGetPartyByIdentificationParty = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('partyDetails').toJS());

const selectIsGetPartyByIdentificationLoading = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isGetPartyByIdentificationLoading'));

const selectIsGetPartyByIdentificationFetched = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isGetPartyByIdentificationFetched'));

const selectPartyByIdentificationError = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('partyByIdentificationError'));

const selectGetPartyByIdParty = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('party').toJS());

const selectIsGetPartyByIdLoading = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isGetPartyByIdLoading'));

const selectIsGetPartyByIdFetched = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isGetPartyByIdFetched'));

const selectPartyByIdError = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('partyByIdError'));

const selectIsUpdatePartyLoading = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isUpdatePartyLoading'));

const selectIsUpdatePartyFetched = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isUpdatePartyFetched'));

const selectIsUpdatePartyError = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('updatePartyError'));

const selectGetPartyNameList = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('partyList').toJS());

const selectIsGetPartyNameListLoading = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isGetPartyNameListLoading'));

const selectIsGetPartyNameListFetched = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isGetPartyNameListFetched'));

const selectIsGetPartyNameListError = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('partyNameListError'));

const selectGetCurrentUserPartyLogoUrl = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('currentUserPartyLogoUrl'));

const selectIsGetCurrentUserPartyLogoUrlLoading = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isGetCurrentUserPartyLogoUrlLoading'));

const selectIsGetCurrentUserPartyLogoUrlFetched = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isGetCurrentUserPartyLogoUrlFetched'));

const selectCurrentUserPartyLogoUrlError = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('currentUserPartyLogoUrlError'));

const selectGetDefaultPartyLogoUrl = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('defaultPartyLogoUrl'));

const selectIsGetDefaultPartyLogoUrlLoading = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isGetDefaultPartyLogoUrlLoading'));

const selectIsGetDefaultPartyLogoUrlFetched = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('isGetDefaultPartyLogoUrlFetched'));

const selectDefaultPartyLogoUrlError = () =>
  createSelector(getPartiesDomain(), (partiesState) => partiesState.get('defaultPartyLogoUrlError'));

export {
  selectGetPartyByIdentificationParty,
  selectIsGetPartyByIdentificationLoading,
  selectIsGetPartyByIdentificationFetched,
  selectPartyByIdentificationError,
  selectGetPartyByIdParty,
  selectIsGetPartyByIdLoading,
  selectIsGetPartyByIdFetched,
  selectPartyByIdError,
  selectIsUpdatePartyLoading,
  selectIsUpdatePartyFetched,
  selectIsUpdatePartyError,
  selectGetPartyNameList,
  selectIsGetPartyNameListLoading,
  selectIsGetPartyNameListFetched,
  selectIsGetPartyNameListError,
  selectGetCurrentUserPartyLogoUrl,
  selectIsGetCurrentUserPartyLogoUrlLoading,
  selectIsGetCurrentUserPartyLogoUrlFetched,
  selectCurrentUserPartyLogoUrlError,
  selectGetDefaultPartyLogoUrl,
  selectIsGetDefaultPartyLogoUrlLoading,
  selectIsGetDefaultPartyLogoUrlFetched,
  selectDefaultPartyLogoUrlError
};

// Actions
export const fetchPartyByIdentification = (identificationNumber: any) => {
  return {
    type: actionTypes.PARTY_BY_IDENTIFICATION_FETCH,
    id: identificationNumber
  };
};

export const fetchPartyByIdentificationSuccess = (result: PartyDetails) => {
  return {
    type: actionTypes.PARTY_BY_IDENTIFICATION_FETCH_SUCCESS,
    payload: result
  };
};

export const fetchPartyByIdentificationError = (error: Error) => {
  return {
    type: actionTypes.PARTY_BY_IDENTIFICATION_FETCH_ERROR,
    payload: error
  };
};

export const fetchPartyById = (partyId: any) => {
  return {
    type: actionTypes.PARTY_BY_ID_FETCH,
    id: partyId
  };
};

export const fetchPartyByIdSuccess = (result: Party) => {
  return {
    type: actionTypes.PARTY_BY_ID_FETCH_SUCCESS,
    payload: result
  };
};

export const fetchPartyByIdError = (error: Error) => {
  return {
    type: actionTypes.PARTY_BY_ID_FETCH_ERROR,
    payload: error
  };
};

export const fetchUpdateParty = (data: Party) => {
  return {
    type: actionTypes.UPDATE_PARTY_FETCH,
    payload: data
  };
};

export const fetchUpdatePartySuccess = (result: Party) => {
  return {
    type: actionTypes.UPDATE_PARTY_FETCH_SUCCESS,
    payload: result
  };
};

export const fetchUpdatePartyError = (error: Error) => {
  return {
    type: actionTypes.UPDATE_PARTY_FETCH_ERROR,
    payload: error
  };
};

export const fetchPartyNameList = () => {
  return {
    type: actionTypes.PARTY_NAME_LIST_FETCH
  };
};

export const fetchPartyNameListSuccess = (result: Party[]) => {
  return {
    type: actionTypes.PARTY_NAME_LIST_FETCH_SUCCESS,
    payload: result
  };
};

export const fetchPartyNameListError = (error: Error) => {
  return {
    type: actionTypes.PARTY_NAME_LIST_FETCH_ERROR,
    payload: error
  };
};

export const fetchCurrentUserPartyLogoUrl = (document: any) => {
  return {
    type: actionTypes.CURRENT_USER_PARTY_LOGO_URL_FETCH,
    id: document
  };
};

export const fetchCurrentUserPartyLogoUrlSuccess = (result: any) => {
  return {
    type: actionTypes.CURRENT_USER_PARTY_LOGO_URL_FETCH_SUCCESS,
    payload: result
  };
};

export const fetchCurrentUserPartyLogoUrlError = (error: Error) => {
  return {
    type: actionTypes.CURRENT_USER_PARTY_LOGO_URL_FETCH_ERROR,
    payload: error
  };
};

export const fetchDefaultPartyLogoUrl = () => {
  return {
    type: actionTypes.DEFAULT_LOGO_URL_FETCH
  };
};

export const fetchDefaultPartyLogoUrlSuccess = (result: any) => {
  return {
    type: actionTypes.DEFAULT_LOGO_URL_FETCH_SUCCESS,
    payload: result
  };
};

export const fetchDefaultPartyLogoUrlError = (error: Error) => {
  return {
    type: actionTypes.DEFAULT_LOGO_URL_FETCH_ERROR,
    payload: error
  };
};


export const fetchPartyUpdatedOnboardStatus = (result: Party) => {
  return {
    type: actionTypes.UPDATE_PARTY_ONBOARD_STATUS_FETCH,
    payload: result
  };
};

// Reducers
export default (state = initialState, action: PartyAction) => {
  switch (action.type) {
    case actionTypes.PARTY_BY_IDENTIFICATION_FETCH:
      return state
        .set('isGetPartyByIdentificationLoading', true)
        .set('isGetPartyByIdentificationFetched', false)
        .set('partyDetails', initialState.get('partyDetails'));
    case actionTypes.PARTY_BY_IDENTIFICATION_FETCH_SUCCESS:
      return state
        .set('isGetPartyByIdentificationLoading', false)
        .set('isGetPartyByIdentificationFetched', true)
        .set('partyDetails', fromJS(action.payload));
    case actionTypes.PARTY_BY_IDENTIFICATION_FETCH_ERROR:
      return state
        .set('isGetPartyByIdentificationLoading', false)
        .set('isGetPartyByIdentificationFetched', false)
        .set('partyByIdentificationError', fromJS(action.payload));
    case actionTypes.PARTY_BY_ID_FETCH:
      return state
        .set('isGetPartyByIdLoading', true)
        .set('isGetPartyByIdFetched', false)
        .set('party', initialState.get('party'));
    case actionTypes.PARTY_BY_ID_FETCH_SUCCESS:
      return state
        .set('isGetPartyByIdLoading', false)
        .set('isGetPartyByIdFetched', true)
        .set('party', fromJS(action.payload));
    case actionTypes.PARTY_BY_ID_FETCH_ERROR:
      return state
        .set('isGetPartyByIdLoading', false)
        .set('isGetPartyByIdFetched', false)
        .set('partyByIdError', fromJS(action.payload));
    case actionTypes.UPDATE_PARTY_FETCH:
      return state
        .set('isUpdatePartyLoading', true)
        .set('isUpdatePartyFetched', false)
        .set('party', initialState.get('party'));
    case actionTypes.UPDATE_PARTY_FETCH_SUCCESS:
      return state
        .set('isUpdatePartyLoading', false)
        .set('isUpdatePartyFetched', true)
        .set('party', fromJS(action.payload));
    case actionTypes.UPDATE_PARTY_FETCH_ERROR:
      return state
        .set('isUpdatePartyLoading', false)
        .set('isUpdatePartyFetched', false)
        .set('updatePartyError', fromJS(action.payload));
    case actionTypes.PARTY_NAME_LIST_FETCH:
      return state
        .set('isGetPartyNameListLoading', true)
        .set('isGetPartyNameListFetched', false)
        .set('partyList', initialState.get('partyList'));
    case actionTypes.PARTY_NAME_LIST_FETCH_SUCCESS:
      return state
        .set('isGetPartyNameListLoading', false)
        .set('isGetPartyNameListFetched', true)
        .set('partyList', fromJS(action.payload));
    case actionTypes.PARTY_NAME_LIST_FETCH_ERROR:
      return state
        .set('isGetPartyNameListLoading', false)
        .set('isGetPartyNameListFetched', false)
        .set('partyNameListError', fromJS(action.payload));
    case actionTypes.CURRENT_USER_PARTY_LOGO_URL_FETCH:
      return state
        .set('isGetCurrentUserPartyLogoUrlLoading', true)
        .set('isGetCurrentUserPartyLogoUrlFetched', false)
        .set('currentUserPartyLogoUrl', initialState.get('currentUserPartyLogoUrl'));
    case actionTypes.CURRENT_USER_PARTY_LOGO_URL_FETCH_SUCCESS:
      return state
        .set('isGetCurrentUserPartyLogoUrlLoading', false)
        .set('isGetCurrentUserPartyLogoUrlFetched', true)
        .set('currentUserPartyLogoUrl', fromJS(action.payload));
    case actionTypes.CURRENT_USER_PARTY_LOGO_URL_FETCH_ERROR:
      return state
        .set('isGetCurrentUserPartyLogoUrlLoading', false)
        .set('isGetCurrentUserPartyLogoUrlFetched', false)
        .set('currentUserPartyLogoUrlError', fromJS(action.payload));
    case actionTypes.DEFAULT_LOGO_URL_FETCH:
      return state
        .set('isGetDefaultPartyLogoUrlLoading', true)
        .set('isGetDefaultPartyLogoUrlFetched', false)
        .set('defaultPartyLogoUrl', initialState.get('defaultPartyLogoUrl'));
    case actionTypes.DEFAULT_LOGO_URL_FETCH_SUCCESS:
      return state
        .set('isGetDefaultPartyLogoUrlLoading', false)
        .set('isGetDefaultPartyLogoUrlFetched', true)
        .set('defaultPartyLogoUrl', fromJS(action.payload));
    case actionTypes.DEFAULT_LOGO_URL_FETCH_ERROR:
      return state
        .set('isGetDefaultPartyLogoUrlLoading', false)
        .set('isGetDefaultPartyLogoUrlFetched', false)
        .set('defaultPartyLogoUrlError', fromJS(action.payload));
    case actionTypes.UPDATE_PARTY_ONBOARD_STATUS_FETCH:
      return state
        .set('party', fromJS(action.payload));
    default:
      return state;
  }
};
