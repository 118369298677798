import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import AppLoader from '../components/AppLoader';
import { fetchUserByEmail } from 'src/store/users/service';
import { actions } from '../helpers/Identity';

type CallbackState = {
  logs: string;
};

interface CallbackProps extends RouteComponentProps<any> {
  /* other props for ChildComponent */
}

export default class Callback extends React.Component<CallbackProps, CallbackState> {
  public componentDidMount() {
    this.authenticateUser();
  }

  public async authenticateUser() {
    try {
      const result: any = await actions.signinRedirectCallback();
      if (result && result.profile && result.profile.name) {
        this.getCurrentUserDetails(result.profile.name);
      }
    } catch (e) {
      console.error(e);
      if (e.message.indexOf('iat is in the future') > -1) {
        console.log('Time issue.......');
      }
      if (e.message.indexOf('login_required') > -1) {
        actions.login();
      }
    }
  }

  public async getCurrentUserDetails(email: any) {
    try {
      const result = await fetchUserByEmail(email);
      if (result) {
        this.navigatetoDefaultUserRoute(result.party.role);
      }
    } catch (error) {
      this.props.history.push('/dashboard');
      console.log(error);
    }
  }

  public navigatetoDefaultUserRoute(role: number) {
    if (role === 1) {
      this.props.history.push('/companies?category=0');
    } else if (role === 2) {
      this.props.history.push('/financing-schemes');
    } else {
      this.props.history.push('/dashboard');
    }
  }

  public render() {
    return <AppLoader />;
  }
}
