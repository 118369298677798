import { call, put, take, delay } from 'redux-saga/effects';

import {
  fetchPartyDetails,
  fetchParty,
  fetchUpdateParty,
  fetchPartyNameList,
  fetchCurrentUserPartyLogoUrlDetails,
  fetchDefaultPartyLogoUrlDetails
} from './service';
import {
  actionTypes,
  fetchPartyByIdentificationSuccess,
  fetchPartyByIdentificationError,
  fetchPartyByIdSuccess,
  fetchPartyByIdError,
  fetchUpdatePartySuccess,
  fetchUpdatePartyError,
  fetchPartyNameListSuccess,
  fetchPartyNameListError,
  fetchCurrentUserPartyLogoUrlSuccess,
  fetchCurrentUserPartyLogoUrlError,
  fetchDefaultPartyLogoUrlSuccess,
  fetchDefaultPartyLogoUrlError,
  fetchPartyUpdatedOnboardStatus
} from './reducer';
import { PartyDetails, Party } from './model';
import { storeApiResultData } from '../../common/reducer';

export function* updateError(data: any) {
  yield put(storeApiResultData(data));
}

export function* fetchPartyByIdentificationNumber(identificationNumber: string) {
  try {
    const result: PartyDetails = yield call(fetchPartyDetails, identificationNumber);

    yield put(fetchPartyByIdentificationSuccess(result));
  } catch (err) {
    yield put(fetchPartyByIdentificationError(err));
  }
}

export function* fetchPartyByIdentificationNumberWatcher() {
  while (true) {
    const { id } = yield take(actionTypes.PARTY_BY_IDENTIFICATION_FETCH);

    yield call(fetchPartyByIdentificationNumber, id);
  }
}

export function* fetchPartyById(id: string) {
  try {
    const result: Party = yield call(fetchParty, id);

    yield put(fetchPartyByIdSuccess(result));
  } catch (err) {
    yield put(fetchPartyByIdError(err));
    window.scrollTo(0, 0);

    yield call(updateError, {
      showMessageClass: 'alert-danger',
      messageCode: 'ErrorMessage.PartyRecordIsNotAvailable',
      showMessageKey: new Date().toUTCString(),
      visibleClass: 'show-alert'
    });

    yield delay(3000);
    yield call(updateError, {
      visibleClass: ''
    });
  }
}

export function* fetchPartyByIdWatcher() {
  while (true) {
    const { id } = yield take(actionTypes.PARTY_BY_ID_FETCH);

    yield call(fetchPartyById, id);
  }
}

export function* fetchUpdatePartyData(partyData: any) {
  try {
    const language = localStorage.getItem('language');
    const result: Party = yield call(fetchUpdateParty, partyData);

    yield put(fetchUpdatePartySuccess(result));
    window.scrollTo(0, 0);

    yield call(updateError, {
      showMessageClass: 'alert-success',
      messageCode: 'SuccessMessage.PartySuccessfullyUpdated',
      showMessageKey: new Date().toUTCString(),
      visibleClass: 'show-alert'
    });

    yield delay(3000);
    yield call(updateError, {
      visibleClass: ''
    });

    if (!window.location.search) {
      if (partyData.language) {
        if (language !== partyData.language.localeCode) {
          localStorage.setItem('language', partyData.language.localeCode);
          window.location.reload();
        }
      }
    }
  } catch (err) {
    yield put(fetchUpdatePartyError(err));
    window.scrollTo(0, 0);

    yield call(updateError, {
      showMessageClass: 'alert-danger',
      messageCode: 'ErrorMessage.PartyUpdateFailed',
      showMessageKey: new Date().toUTCString(),
      visibleClass: 'show-alert'
    });

    yield delay(3000);
    yield call(updateError, {
      visibleClass: ''
    });
  }
}

export function* fetchUpdatePartyWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.UPDATE_PARTY_FETCH);
    yield call(fetchUpdatePartyData, payload);
  }
}

export function* fetchPartyNames() {
  try {
    const result: Party[] = yield call(fetchPartyNameList);
    yield put(fetchPartyNameListSuccess(result));
  } catch (err) {
    yield put(fetchPartyNameListError(err));
  }
}

export function* fetchPartyNamesWatcher() {
  while (true) {
    yield take(actionTypes.PARTY_NAME_LIST_FETCH);

    yield call(fetchPartyNames);
  }
}

export function* fetchCurrentUserPartyLogo(document: any) {
  try {
    const result: any = yield call(fetchCurrentUserPartyLogoUrlDetails, document);
    yield put(fetchCurrentUserPartyLogoUrlSuccess(result));
  } catch (err) {
    yield put(fetchCurrentUserPartyLogoUrlError(err));
  }
}

export function* fetchCurrentUserPartyLogoWatcher() {
  while (true) {
    const { id } = yield take(actionTypes.CURRENT_USER_PARTY_LOGO_URL_FETCH);

    yield call(fetchCurrentUserPartyLogo, id);
  }
}

export function* fetchDefaultPartyLogo() {
  try {
    const result: any = yield call(fetchDefaultPartyLogoUrlDetails);
    yield put(fetchDefaultPartyLogoUrlSuccess(result));
  } catch (err) {
    yield put(fetchDefaultPartyLogoUrlError(err));
  }
}

export function* fetchDefaultPartyLogoWatcher() {
  while (true) {
    yield take(actionTypes.DEFAULT_LOGO_URL_FETCH);

    yield call(fetchDefaultPartyLogo);
  }
}

export function* fetchUpdatePartyOnboardStatus(partyData: any) {
    yield put(fetchPartyUpdatedOnboardStatus(partyData));
}

export function* fetchUpdatePartyOnboardStatusWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.UPDATE_PARTY_ONBOARD_STATUS_FETCH);
    yield call(fetchUpdatePartyOnboardStatus, payload);
  }
}
