import React from 'react';

const RouteNotFound = () => {
  return (
    <div className="no-data-emptypage d-flex align-items-center justify-content-center">
      <div className="text-center">
        <i className="mdi  mdi-block-helper" />
        <div>
          <h3>Page Not Found</h3>
        </div>
      </div>
    </div>
  );
};

export default RouteNotFound;
