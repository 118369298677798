import configurations from '../../../config/index';
import { get, post, put } from '../../../helpers/apiHelper';

// Get the Party Information Using The Party Identification number
export const fetchPartyDetails = (identificationNumber: string) => {
  return get(`/api/party/partyIdentification/${identificationNumber}`, false)
    .then((response: any) => {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        return Promise.reject(response);
      }
    })
    .catch((error) => {
      console.log('There was an error in getting the party details', error);
      return Promise.reject(error);
    });
};

// Get the Party Information Using The Party ID
export const fetchParty = (id: string) => {
  return get(`/api/party/${id}`, true)
    .then((response: any) => {
      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        return Promise.reject(response);
      }
    })
    .catch((error) => {
      console.log('There was an error in getting the party details', error);
      return Promise.reject(error);
    });
};

// Get the Party Names
export const fetchPartyNameList = () => {
  return get(`/api/party/all-names`, true)
    .then((response: any) => {
      if (response.data.status) {
        return response.data.value;
      } else {
        return Promise.reject(response.data);
      }
    })
    .catch((error) => {
      console.log('There was an error in getting the party names list', error);
      return Promise.reject(error);
    });
};

// Save Party Details
export const fetchUpdateParty = async (requestData: any) => {
  try {
    const result = await put(`/api/party/${requestData.id}`, requestData, true);
    if (result.status === 200 || result.status === 201) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

// Get the Party Registration Schemes
export const fetchPartyRegistrationSchemes = async () => {
  try {
    const result = await get(`/api/service/getRegistrationSchemes`, true);
    if (result.status === 200 || result.status === 201) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

// Get Users of a Party
export const fetchPartyUsers = async (partyId: any, page: number, size: number) => {
  try {
    const result = await get(`/api/party/${partyId}/user?page=${page}&size=${size}`, true);

    if (result.data.status) {
      return { totalResults: result.data.totalCount, data: result.data.value };
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Get Party List
export const fetchParties = async (requestData: any) => {
  const queryString = `page=${requestData.page}&size=${requestData.size}&searchString=${requestData.searchString}&orderBy=${requestData.orderBy}&descending=${requestData.descending}&value=${requestData.role}`;

  try {
    const result = await get(`/api/Party?${queryString}`, true);

    if (result.data.status) {
      return { totalResults: result.data.totalCount, data: result.data.value };
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Get Industry Classification Types
export const fetchIndustryClassificationTypes = async () => {
  try {
    const result = await get(configurations.backgroundInformationServiceApis.industryClassificationUrl, true);
    if (result.status === 200 || result.status === 201) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

// Get Industry Classification Types
export const fetchCodesByIndustryClassificationType = async (standard: string, code: string) => {
  try {
    const result = await get(
      configurations.backgroundInformationServiceApis.getIndustryclassificationCode(standard, code),
      true
    );
    if (result.status === 200 || result.status === 201) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchPartiesWithoutSuppliers = async (partyId: string) => {
  try {
    const result = await get(`/api/party/${partyId}/all-names-suppliers`, true);
    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Upload company logo
export const fetchSaveDocuments = async (files: File[], partyId: any) => {
  const requestFormData = new FormData();
  files.forEach((file: any) => {
    requestFormData.append('files', file);
    requestFormData.append('name', file.name);
    requestFormData.append('category', '');
  });

  try {
    const result = await post(`/api/Party/${partyId}/logo`, requestFormData, true, 'multipart/form-data');

    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchCurrentUserPartyLogoUrlDetails = (document: any) => {
  return post(`/api/File/GetFileUrl`, document, true)
    .then((response: any) => {
      if (response.data.status) {
        return response.data.value;
      } else {
        return Promise.reject(response);
      }
    })
    .catch((error) => {
      console.log('There was an error in getting the logo url', error);
      return Promise.reject(error);
    });
};

// Get the Party Legal Forms
export const fetchPartyLegalForms = async () => {
  try {
    const result = await get(configurations.backgroundInformationServiceApis.getPartyLegalForms, true);
    if (result.status === 200 || result.status === 201) {
      return result.data;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchPartyContactDetails = async (partyId: string) => {
  try {
    const result = await get(`/api/party/${partyId}/contact`, true);
    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchDefaultPartyLogoUrlDetails = () => {
  return get(`/api/values/default-logo`, false)
    .then((response: any) => {
      if (response.data.status) {
        return response.data.value;
      } else {
        return Promise.reject(response);
      }
    })
    .catch((error) => {
      console.log('There was an error in getting the default logo url', error);
      return Promise.reject(error);
    });
};

// Get All Users of a Party
export const fetchAllPartyUsers = async (partyId: any) => {
  try {
    const result = await get(`/api/party/${partyId}/users/dropdown`, true);

    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

// Invite Contact Party
export const fetchInviteParty = async (partyId: string, requestData: any) => {
  try {
    const result = await put(`/api/party/invitation/${partyId}`, requestData, true);

    if (result.data.status) {
      return result.data.value;
    } else {
      throw result.data;
    }
  } catch (error) {
    throw error;
  }
};

export const fetchUpdatePartyOnboardStatus = async (partyId: any, status: any) => {
  try {
    const result = await put(`/api/party/${partyId}/status/${status}`, {}, true);

    if (result.status === 200 || result.status === 201) {
      return true;
    } else {
      throw result;
    }
  } catch (error) {
    throw error;
  }
}
