import React from 'react';
import * as ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router/immutable';
import App from './App';
import store, { history } from './store/store';
// import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'react-redux';
import { unregister as unregisterServiceWorker } from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import configurations from './config';
import { hotjar } from 'react-hotjar';

Sentry.init({
  dsn: configurations.sentryDSN,
  integrations: [new Integrations.BrowserTracing()],
  environment: window.location.host.match('portal.seaeasycapital.com') ? 'production' : 'development',
  tracesSampleRate: 1.0
});

if (window.location.host.match('portal.seaeasycapital.com')) {
  hotjar.initialize(configurations.hotJarConfig.hjid, configurations.hotJarConfig.hjsv);
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root') as HTMLElement
);
// registerServiceWorker();
unregisterServiceWorker();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
