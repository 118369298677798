import axios from 'axios';
import { actions } from './Identity';
import configurations from '../config';

const baseURL = configurations.apiBaseUrl;

export async function post(
  url: string,
  data: any,
  authenticationRequired: boolean = false,
  headersContentType?: string,
  responseType?: string
) {
  let options: any = {
    data,
    baseURL,
    url,
    method: 'post',
    responseType: responseType ? responseType : 'json',
    crossDomain: true,
    timeout: 80000
  };

  if (headersContentType) {
    options.headers = new Headers({
      'Content-Type': headersContentType
    });
  }

  options = await addRequestHeaders(options, authenticationRequired);
  return axios(options);
}

export async function get(
  url: string,
  authenticationRequired: boolean = false,
  responseType?: string,
  customBaseUrl?: string
) {
  let options: any = {
    data: null,
    baseURL: customBaseUrl || baseURL,
    url,
    method: 'get',
    responseType: responseType ? responseType : 'json',
    crossDomain: true,
    timeout: 80000
  };

  options = await addRequestHeaders(options, authenticationRequired);
  return axios(options);
}

export async function remove(url: string, authenticationRequired: boolean = false, customBaseUrl?: string, data?: any) {
  let options: any = {
    data,
    baseURL: customBaseUrl || baseURL,
    url,
    method: 'delete',
    responseType: 'json',
    crossDomain: true,
    timeout: 80000
  };

  options = await addRequestHeaders(options, authenticationRequired);
  return axios(options);
}

export async function put(
  url: string,
  data: any,
  authenticationRequired: boolean = false,
  headersContentType?: string
) {
  let options: any = {
    data,
    baseURL,
    url,
    method: 'put',
    responseType: 'json',
    crossDomain: true,
    timeout: 80000
  };

  if (headersContentType) {
    options.headers = new Headers({
      'Content-Type': headersContentType
    });
  }

  options = await addRequestHeaders(options, authenticationRequired);
  return axios(options);
}

async function addRequestHeaders(options: any, authenticationRequired: boolean) {
  const acessToken = await actions.getAccessToken();
  options.headers = {};
  if (authenticationRequired) {
    if (acessToken) {
      options.headers.Authorization = 'Bearer '.concat(acessToken);
    }
  }
  return options;
}

axios.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error: any) => {
    if (error.response && error.response.status === 401) {
      actions.login();
    } else {
      throw error;
    }
  }
);
