import React from 'react';

const AppLoader = (props: any) => (
  <div className="app-loader">
    <div className="loader">
      <div className="lds-roller">
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  </div>
);

export default AppLoader;
