import { call, take } from 'redux-saga/effects';
import { actionTypes, storeSelectedFinancingSchemeData } from './reducer';

export function* storeSelectedFinancingSchemeDetails(financingSchemeData: any) {
  yield call(storeSelectedFinancingSchemeData, financingSchemeData);
}

export function* storeSelectedFinancingSchemeDataWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.STORE_SELECTED_FINANCING_SCHEME_DATA);

    yield call(storeSelectedFinancingSchemeDetails, payload);
  }
}
