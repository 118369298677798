import { fetchCurrentUserWatcher, storeUserRegistrationDataWatcher, fetchUpdateCurrentUserWatcher } from './users/saga';
import {
  fetchPartyByIdentificationNumberWatcher,
  fetchUpdatePartyWatcher,
  fetchPartyByIdWatcher,
  fetchPartyNamesWatcher,
  fetchCurrentUserPartyLogoWatcher,
  fetchDefaultPartyLogoWatcher,
  fetchUpdatePartyOnboardStatusWatcher
} from './parties/party/saga';
import { fetchCountriestWatcher } from './countries/saga';
import { storeSelectedLegalEntityDataWatcher } from './parties/legalEntities/legalEntity/saga';
import { storeApiResultDataWatcher } from './common/saga';
import { storeSelectedSupplierDataWatcher } from './suppliers/saga';
import { storeSelectedPayableDataWatcher, storeSelectedPayableDraftDataWatcher } from './payables/saga';
import { storeSelectedPurchaserDataWatcher } from './purchasers/saga';
import { storeSelectedReceivableDataWatcher } from './receivables/saga';
import { storeSelectedFinancingSchemeDataWatcher } from './purchaserPortfolios/financingSchemes/saga';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all([
    fetchPartyByIdentificationNumberWatcher(),
    fetchCurrentUserWatcher(),
    storeUserRegistrationDataWatcher(),
    fetchPartyByIdWatcher(),
    fetchUpdateCurrentUserWatcher(),
    fetchUpdatePartyWatcher(),
    fetchPartyNamesWatcher(),
    fetchCountriestWatcher(),
    storeSelectedLegalEntityDataWatcher(),
    storeApiResultDataWatcher(),
    storeSelectedSupplierDataWatcher(),
    storeSelectedPayableDataWatcher(),
    storeSelectedPurchaserDataWatcher(),
    storeSelectedReceivableDataWatcher(),
    storeSelectedPayableDraftDataWatcher(),
    fetchCurrentUserPartyLogoWatcher(),
    storeSelectedFinancingSchemeDataWatcher(),
    fetchDefaultPartyLogoWatcher(),
    fetchUpdatePartyOnboardStatusWatcher()
  ]);
}
