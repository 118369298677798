import { fromJS } from 'immutable';
import { Country } from './model';
import { CountryAction } from './model';
import { createSelector } from 'reselect';

// State
const initialState = fromJS({
  countries: []
});

// Action Types
export const actionTypes = {
  COUNTRY_LIST_FETCH: 'COUNTRIES/COUNTRY_LIST_FETCH',
  COUNTRY_LIST_FETCH_SUCCESS: 'COUNTRIES/COUNTRY_LIST_FETCH_SUCCESS',
  COUNTRY_LIST_FETCH_ERROR: 'COUNTRIES/COUNTRY_LIST_FETCHH_ERROR'
};

// Selectors
const getCountriesDomain = () => (state: any) => state.get('countriesDomain');

const selectGetCountryList = () =>
  createSelector(getCountriesDomain(), (countriesState) => countriesState.get('countries').toJS());

const selectIsCountryListLoading = () =>
  createSelector(getCountriesDomain(), (countriesState) => countriesState.get('isGetCountryListLoading'));

const selectIsCountryListFetched = () =>
  createSelector(getCountriesDomain(), (countriesState) => countriesState.get('isGetCountryListFetched'));

const selectCountryListError = () =>
  createSelector(getCountriesDomain(), (countriesState) => countriesState.get('countryListError'));

export { selectGetCountryList, selectIsCountryListLoading, selectIsCountryListFetched, selectCountryListError };

// Actions
export const fetchCountryList = () => {
  return {
    type: actionTypes.COUNTRY_LIST_FETCH
  };
};

export const fetchCountryListSuccess = (result: Country[]) => {
  return {
    type: actionTypes.COUNTRY_LIST_FETCH_SUCCESS,
    payload: result
  };
};

export const fetchCountryListError = (error: Error) => {
  return {
    type: actionTypes.COUNTRY_LIST_FETCH_ERROR,
    payload: error
  };
};

// Reducers
export default (state = initialState, action: CountryAction) => {
  switch (action.type) {
    case actionTypes.COUNTRY_LIST_FETCH:
      return state
        .set('isGetCountryListLoading', true)
        .set('isGetCountryListFetched', false)
        .set('countries', initialState.get('countries'));
    case actionTypes.COUNTRY_LIST_FETCH_SUCCESS:
      return state
        .set('isGetCountryListLoading', false)
        .set('isGetCountryListFetched', true)
        .set('countries', fromJS(action.payload));
    case actionTypes.COUNTRY_LIST_FETCH_ERROR:
      return state
        .set('isGetCountryListLoading', false)
        .set('isGetCountryListFetched', false)
        .set('countryListError', fromJS(action.payload));
    default:
      return state;
  }
};
