import { call, take } from 'redux-saga/effects';
import { actionTypes, storeSelectedLegalEntityData } from './reducer';

export function* storeSelectedLegalEntityDetails(legalEntityData: any) {
  yield call(storeSelectedLegalEntityData, legalEntityData);
}

export function* storeSelectedLegalEntityDataWatcher() {
  while (true) {
    const { payload } = yield take(actionTypes.STORE_SELECTED_LEGAL_ENTITY_DATA);

    yield call(storeSelectedLegalEntityDetails, payload);
  }
}
